import { HttpClient } from '@angular/common/http';
import { Component, NgModule, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.css'],
})
export class RegisterFormComponent implements OnInit {
  registerForm: any = FormGroup;
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private _snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      phone: ['', Validators.required],
      qualification: ['', Validators.required],
      age: ['', Validators.required],
      experience: ['', Validators.required],
      expectedSalary: ['', Validators.required],
      lastCompany: ['', Validators.required],
      lastSalary: ['', Validators.required],
      preferredWorkLocation: ['', Validators.required],
    });
  }

  get name() {
    return this.registerForm.get('name');
  }

  get address() {
    return this.registerForm.get('address');
  }

  get phone() {
    return this.registerForm.get('phone');
  }

  get age() {
    return this.registerForm.get('age');
  }

  get qualification() {
    return this.registerForm.get('qualification');
  }

  get experience() {
    return this.registerForm.get('experience');
  }

  get lastCompany() {
    return this.registerForm.get('lastCompany');
  }

  get lastSalary() {
    return this.registerForm.get('lastSalary');
  }

  get expectedSalary() {
    return this.registerForm.get('expectedSalary');
  }

  get preferredWorkLocation() {
    return this.registerForm.get('preferredWorkLocation');
  }

  register() {
    this.http
      .post('http://localhost:3000/mailManager', this.registerForm.value)
      .subscribe(
        (res: any) => {
          console.log(res);
          this._snackBar.open('Registeration Successful', 'Success', {
            duration: 2000,
            verticalPosition: 'top',
            panelClass: ['blue-snackbar'],
          });

          this.router.navigate(['../placement']);
        },
        (err) => {
          this._snackBar.open('Registeration Unsuccessful', 'Failed', {
            duration: 2000,
            panelClass: ['red-snackbar'],
            verticalPosition: 'top',
          });
        }
      );
  }

  // list
  selectedExperience: string = '';
  selected(): void {
    console.log(this.selectedExperience);
  }

  // cancel
  cancel(): void {
    this.router.navigate(['../placement']);
  }

}
