<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
<app-header></app-header>
<div class="container-fluid mx-1 my-1">
  <div class="container">
    <div class="row justify-content-center align-items-center">
        <div class="col-md-7 mt-5 text-center">
          <h1 class="text-center font-italic">Looking for placement, you are at right place.</h1>
          <h1 class="text-center text-uppercase font-weight-bold">We can help you to find your dream jobs.</h1>
        <button  mat-button routerLink="../register" class="btn btn-primary shadow-lg text-uppercase btn-lg bgcolor">Register</button>
        </div>
        <div class="col-md-4">
            <img src="../../assets/clip-942.png" alt="" class="w-100 h-50">
        </div>
    </div>
  </div>

<!-- Steps includes -->
  <div class="container">
    <div class="row">
      <div class="col-md-4">

      </div>
      <div class="col-md-4">

      </div>
      <div class="col-md-4">

      </div>

    </div>

  </div>


</div>


<app-footer></app-footer>
