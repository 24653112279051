import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CallcenterComponent } from './callcenter/callcenter.component';
import { GraphicsComponent } from './graphics/graphics.component';
import { HomeComponent } from './home/home.component';
import { PlacementComponent } from './placement/placement.component';
import { RegisterFormComponent } from './register-form/register-form.component';
import { ServicesComponent } from './services/services.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'placement', component: PlacementComponent },
  { path: 'graphics', component: GraphicsComponent },
  { path: 'callcenter', component: CallcenterComponent },
  { path: 'register', component: RegisterFormComponent },
  { path: 'services', component:ServicesComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
