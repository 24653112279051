<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
<div class="container-fluid card shadow-sm p-4 mb-5 rounded bgcard pt-5">
  <div class="row justify-content-center align-items-center text_font">
    <div class="col-md-5">
        <img src="../../assets/logo 1.jpg" width="200" height="80">
        <h2>AB Infotech Solutions</h2>
        <h3 class="mt-3">Founded in 2019 and having 50+ clients across India. Providing business outsourcing services
        to mid-size companies.</h3>
        <a href="tel:+918999897754" class="text_font"><h1>Contact Us: 8999897754</h1></a>

    </div>
    <div class="col ml-5">
      <h1 class="text_font">Quick Links</h1>
      <a href="#"><h3 class="text_font">Home</h3></a>
      <a routerLink="../services">
        <h3 class="text_font">Services</h3>
      </a>
      <a routerLink="../graphics">
        <h3 class="text_font">Contact Us</h3>
      </a>

      <div class="row justify-content-center align-items-center text_font">
        <div class="col ">
          <i class="fab fa-facebook-f fa-2x mr-3"></i>
          <i class="fab fa-linkedin fa-2x mr-3"></i>
          <i class="fab fa-twitter fa-2x mr-3"></i>
        </div>
      </div>
    </div>

    <div class="col ml-5">
      <h1 class="text_font">Our Services</h1>
      <h3 class="text_font">Call Center Services</h3>
      <h3 class="text_font">Training and Placement Services</h3>
      <h3 class="text_font">Consultant</h3>
      <h3 class="text_font">Graphic Designing Services</h3>
    </div>

    <div class="col ml-5">
      <h1 class="text_font">Office Address</h1>
      <h2>INDIA</h2>
      <div class="row justify-content-center align-items-center text_font">
        <div class="col">
          <i class="fas fa-envelope"></i>
          <a href="mailto:abinfotechsolutions@gmail.com">
          <p style="display: inline-block;" class="ml-3 text_font">abinfotechsolutions@gmail.com</p></a>
          <br/>
          <i class="fas fa-map-marker-alt mb-2">
            <h4 class="ml-2" style="display: inline;">Office no 111
            First floor
            Goodwill square mall
            Kamal park
            Dhanori road
            Pune 411015</h4>
          </i>
          <br/>
          <i class="fas fa-mobile-alt mr-2"  style="display: inline;">
            <a href="tel:+918999897754" class="text_font ml-2">8999897754</a>
          </i>
          <i class="fas fa-mobile-alt" style="display: inline;">
            <a href="tel:+919766520456" class="text_font ml-2">9766520456</a>
          </i>
          <br />
          <i class="fas fa-mobile-alt" style="display: inline;">
            <a href="tel:+919767046818" class="text_font ml-2">9767046818</a>
          </i>

          <div class="mt-4">
            @copyright - AB Infotech Solutions
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
