<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
<!-- Home page contact form -->
<form [formGroup]="registerForm">
  <div class="row text-color">
    <div class="col-md-12 ">
      <mat-form-field>
        <mat-label>Full Name*</mat-label>
        <input matInput formControlName="name" type="text">
      </mat-form-field>
    </div>
    <div class="col-md-12">
      <mat-form-field color="warn">
        <mat-label>Email Id*</mat-label>
        <input matInput formControlName="email" type="text">
      </mat-form-field>
    </div>
    <div class="col-md-12">
      <mat-form-field color="warn">
        <mat-label>Mobile No*</mat-label>
        <input matInput formControlName="phone" type="text">
      </mat-form-field>
    </div>
  </div>

  <div class="row justify-content-center align-items-center">
    <div class="col-md-12">
      <button mat-raised-button color="primary" class="save" (click)="cancel()">Submit</button>
    </div>
  </div>
</form>
