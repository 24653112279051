<app-header></app-header>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 bgimg">
      <img
        src="  https://images.unsplash.com/photo-1556745753-b2904692b3cd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=666&q=80"
        alt="Random first slide" class="w-100 h-10" style=" height: 70vh">
    </div>
  </div>


  <div class="row justify-content-center align-items-center section">
    <p class="text-center" style="font-size: 4em;">Our Services</p>
  </div>
  <div class="container-fluid pt-5">
    <div class="container pt-5 pb-5 ">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-12">
          <div class="card card1 p-4 shadow-lg mb-4 bg-white text-center text-white">
            <div class="card-body bgcard">
              <h1 class="card-title alert alert-primary shadow-lg ">Consultant</h1>
              <div class="card-text text-align-center ">
                <div class="row ">
                  <div class="col-md-6 ">
                    <ul>
                      <li>
                          <h2>Banking</h2>
                      </li>
                      <li>
                        <h2>Housekeeping/Security</h2>
                      </li>
                      <li>
                        <h2>Back-Office Services</h2>
                      </li>
                      <li>
                        <h2>Finance</h2>
                      </li>
                    </ul>
                  </div>

                  <div class="col-md-6">
                    <ul>
                      <li>
                        <h2>BPO / RPO</h2>
                      </li>
                      <li>
                        <h2>Retail</h2>
                      </li>
                      <li>
                        <h2>MIDC</h2>
                      </li>
                      <li>
                        <h2>Telecalling</h2>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="card card1 shadow-lg p-4 mb-4 mt-4 bg-white text-center text-white">
            <div class="card-body bgcard">
              <h1 class="card-title alert alert-primary shadow-lg">Call Center Services</h1>
              <div class="card-text text-align-center">
                <ul>
                  <li>
                    <h2>Inbound Services</h2>
                  </li>
                  <li>
                    <h2>Outbound Services</h2>
                  </li>
                  <li>
                    <h2>Back-Office Services</h2>
                  </li>
                  <li>
                    <h2>Telecalling Services</h2>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="card card1 shadow-lg p-3 mb-4 mt-4 bg-white text-center text-white">
            <div class="card-body bgcard">
              <h1 class="card-title alert alert-primary shadow-lg">Training & Placement</h1>
              <div class="card-text">
                <ul>
                  <li>
                    <h2>Business Communications</h2>
                  </li>
                  <li>
                    <h2>Basic / Advanced Excel</h2>
                  </li>
                  <li>
                    <h2>VBA / Micro</h2>
                  </li>
                  <li>
                    <h2>Interview Skills</h2>
                  </li>
                  <li>
                    <h2>Soft Skills Development</h2>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="card card1 shadow-lg p-3 mb-3 mt-4 bg-white text-center text-white">
            <div class="card-body bgcard">
              <h1 class="card-title alert alert-primary shadow-lg">Graphics Designing</h1>
              <div class="card-text">
                <ul>
                  <li>
                    <h2>Social Media Post</h2>
                  </li>
                  <li>
                    <h2>Visting and Wedding Card</h2>
                  </li>
                  <li>
                    <h2>Logo Making</h2>
                  </li>
                  <li>
                    <h2>Business Post</h2>
                  </li>
                  <li>
                    <h2>Soft Skills Development</h2>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>

<app-footer></app-footer>
