<app-header></app-header>
<div class="container-fluid mx-2 my-2">
  <div class="row justify-content-center p-5">
    <div class="col-md-8">
      <mat-card>
        <div class="row justify-content-center p-4">
        <mat-card-title class="text_color">Registration Form</mat-card-title>
        </div>
        <div class="row justify-content-center mb-4">
          <img src="../../assets/form_filling.svg" alt="" class="w-50 h-20" width="50%" height="20">
        </div>

        <form [formGroup]="registerForm">
          <mat-card-subtitle>BASIC INFORMATION</mat-card-subtitle>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field appearance="fill">
                <mat-label>Candidate Full name</mat-label>
                <input matInput formControlName="name" type="text">
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <mat-form-field appearance="fill">
                <mat-label>Current Address</mat-label>
                <input matInput formControlName="address" type="text">
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <mat-form-field appearance="fill">
                <mat-label>Phone Number</mat-label>
                <input matInput formControlName="phone" type="number">
                <button mat-button matSuffix mat-icon-button>
                  <mat-icon>phone</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field appearance="fill">
                <mat-label>Age </mat-label>
                <input matInput formControlName="age" type="number">
              </mat-form-field>
            </div>
          </div>
          <mat-card-subtitle>PROFESSIONAL INFORMATION</mat-card-subtitle>

          <div class="row">
            <div class="col-md-6">
              <mat-form-field appearance="fill">
                <mat-label>Qualification</mat-label>
                <input matInput formControlName="qualification" type="text">
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="fill">
                <mat-label>Experience</mat-label>
                <mat-select formControlName="experience" (selectionChange)="selected()" [(ngModel)]="selectedExperience">
                  <mat-option value="Fresher">Fresher</mat-option>
                  <mat-option value="1 - 2 yrs">1 - 2 yrs</mat-option>
                  <mat-option value="3 - 5 yrs">3 - 5 yrs</mat-option>
                  <mat-option value="6 - 8 yrs">6 - 8 yrs</mat-option>
                  <mat-option value="8 - 9 yrs">8 - 9 yrs</mat-option>
                  <mat-option value="10+ yrs">10+ yrs</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <mat-form-field appearance="fill">
                <mat-label>Last Company Name (if any)</mat-label>
                <input matInput formControlName="lastCompany" type="text">
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="fill">
                <mat-label>Last Salary (if any)</mat-label>
                <input matInput formControlName="lastSalary" type="number">
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <mat-form-field appearance="fill">
                <mat-label>Expected Salary</mat-label>
                <input matInput formControlName="expectedSalary" type="number">
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="fill">
                <mat-label>Preferred Work Location</mat-label>
                <input matInput formControlName="preferredWorkLocation" type="text" hint="eg. Pune">
              </mat-form-field>
            </div>
          </div>

          <div class="row justify-content-center align-items-center">
            <div class="col-md-6 mt-2">
              <button mat-raised-button color="primary" class="save" (click)="register()">REGISTER </button>
            </div>
            <div class="col-md-6 mt-2">
              <button mat-raised-button color="primary" class="save" (click)="cancel()">CANCEL</button>
            </div>
          </div>
        </form>
      </mat-card>
    </div>
  </div>
</div>
<app-footer></app-footer>
