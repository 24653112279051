import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-callcenter',
  templateUrl: './callcenter.component.html',
  styleUrls: ['./callcenter.component.css']
})
export class CallcenterComponent implements OnInit {

 registerForm: any = FormGroup;
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private _snackBar: MatSnackBar,
  ) {}


  ngOnInit(): void {

  this.registerForm = this.fb.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
    });
  }

  get name() {
    return this.registerForm.get('name');
  }

  get email() {
    return this.registerForm.get('email');
  }

  get phone() {
    return this.registerForm.get('phone');
  }


  register() {
    this.http
      .post('http://localhost:3000/mailManager', this.registerForm.value)
      .subscribe(
        (res: any) => {
          console.log(res);
          this._snackBar.open('Registeration Successful', 'Success', {
            duration: 2000,
            verticalPosition: 'top',
            panelClass: ['blue-snackbar'],
          });

          this.router.navigate(['../placement']);
        },
        (err) => {
          this._snackBar.open('Registeration Unsuccessful', 'Failed', {
            duration: 2000,
            panelClass: ['red-snackbar'],
            verticalPosition: 'top',
          });
        }
      );
  }

  // cancel
  cancel(): void {
    this.router.navigate(['../placement']);
  }
}
