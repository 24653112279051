<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
<!-- contact page -->
<app-header></app-header>
<div class="container-fluid mx-1 my-1 p-2 text-align-center">
  <div class="row">
    <div class="col-md-12">
      <img
        src="https://images.unsplash.com/photo-1598257006458-087169a1f08d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80"
        alt="Random first slide" class="w-100 h-10" style=" height: 70vh">
    </div>
  </div>
  <div class="row justify-content-center align-items-center section">
    <p class="text-center" style="font-size: 4em;">Contact Us</p>
  </div>

  <div class="row text-align-center align-items-center">
      <div class="col-md-6 text_color">
        <h1>You Are Just A Click Away Of A Free Consultation.</h1>
        <div class="text-center">
          <a href="tel:+919766520456" class="btn btn_color shadow-lg text-uppercase btn-lg">Contact Us</a>
        </div>
        <img src="../../assets/undraw_phone_call_grmk.svg" alt="" class="w-100 h-10">
      </div>
    <div class="col-md-6 card border border-primary">
      <div class="card-body">
        <h2><i class="fas fa-mobile-alt mr-4" style="display: inline;">
            <a href="tel:+918999897754" class="text_color ml-2">8999897754</a>
          </i>
          <i class="fas fa-mobile-alt mr-4" style="display: inline;">
            <a href="tel:+919766520456" class="text_color ml-2">9766520456</a>
          </i>
          <i class="fas fa-mobile-alt mr-4" style="display: inline;">
            <a href="tel:+919767046818" class="text_color ml-2">9767046818</a>
          </i>
        </h2>

        <i class="fas fa-map-marker-alt mb-2">
          <h2 class="ml-2" style="display: inline;">Office no 111
          First floor
          Goodwill square mall
          Kamal park
          Dhanori road
          Pune 411015</h2>
        </i>

      <div class="mapouter">
        <div class="gmap_canvas"><iframe width="600" height="500"
            src="https://maps.google.com/maps?q=Goodwill%20square%20mall%20Kamal%20park%20%20Dhanori%20road%20Pune%20411015&t=&z=13&ie=UTF8&iwloc=&output=embed"
            frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
        </div>
      </div>
      </div>
    </div>

  </div>
</div>
<app-footer></app-footer>
