<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
<app-header></app-header>
<div class="container-fluid">
  <ngb-carousel>
    <ng-template ngbSlide *ngFor="let images of images">
      <div class="carousel-caption p-3 shadow-lg mb-2 text-center bg-gradient-primary">
        <a href="tel:+918999897754" class="text_font">
          <h3><button type="button" class="btn btn-large rounded-5 button_color1">Get In touch</button></h3>
        </a>
      </div>
      <ngx-picture [src]="images"
        alt="rainbow bridge" [lazyLoad]="true" class="w-100 h-20" style=" height: 80vh"></ngx-picture>
    </ng-template>
  </ngb-carousel>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path fill="#0000ca" fill-opacity="0.7" d="M0,96L1440,160L1440,0L0,0Z"></path>
    <text x="190" y="70" fill="white" font-size="2.3em"
      font-family="'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida SansUnicode', 'Geneva', Verdana, sans-serif">Global
      Business Process Outsourcing & Call Center Outsourcing Company
    </text>
  </svg>
  <div class="row justify-content-center align-items-center section">
    <p class="text-center" style="font-size: 4em;">Our Services</p>
  </div>
  <div class="container-fluid pt-5 bgContainer">
    <div class="container pt-5 pb-5 ">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-8 col-sm-8">
          <div class="card card1 shadow-lg p-4 mb-4 mt-4 bg-white">
            <div class="card-body">
              <ngx-picture src="../../assets/undraw_online_banking_7jy4.svg" alt="rainbow bridge" [lazyLoad]="true" class="w-50 h-20" width="50%" height="20%" media="(max-width: 599.99px)" type="image/webp" >
              </ngx-picture>
              <h1 class="card-title alert alert-primary shadow-lg">Consultant</h1>
              <div class="card-text text-align-center ">
                <div class="row ">
                  <div class="col-md-6">
                    <ul>
                      <li>
                        <h3>Banking</h3>
                      </li>
                      <li>
                        <h3>Housekeeping/Security</h3>
                      </li>
                      <li>
                        <h3>Back-Office Services</h3>
                      </li>
                      <li>
                        <h3>Finance</h3>
                      </li>
                    </ul>
                  </div>

                  <div class="col-md-6">
                    <ul>
                      <li>
                        <h3>BPO / RPO</h3>
                      </li>
                      <li>
                        <h3>Retail</h3>
                      </li>
                      <li>
                        <h3>MIDC</h3>
                      </li>
                      <li>
                        <h3>Telecalling</h3>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
              <div>
                <a routerLink="./callcenter" class="btn button_color shadow-lg text-uppercase btn-lg ml-4 mt-3">Read
                  more</a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-sm-6">
          <div class="card card1 shadow-lg mb-4 mt-4 bg-white">
            <div class="card-body">
              <ngx-picture src="../../assets/callcenter.svg" alt="rainbow bridge" [lazyLoad]="true" class="w-100 h-20"
                width="100%" height="20%" media="(max-width: 599.99px)" type="image/webp">
              </ngx-picture>
              <h1 class="card-title alert alert-primary shadow-lg">Call Center Services</h1>
              <div class="card-text text-align-center">
                <ul>
                  <li>
                    <h3>Inbound Services</h3>
                  </li>
                  <li>
                    <h3>Outbound Services</h3>
                  </li>
                  <li>
                    <h3>Back-Office Services</h3>
                  </li>
                  <li>
                    <h3>Telecalling Services</h3>
                  </li>
                </ul>
              </div>
              <a routerLink="./callcenter" mat-button
                class="btn text-center button_color shadow-lg text-uppercase btn-lg  ml-4 mt-3">Read more</a>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-sm-6">
          <div class="card card1 shadow-lg p-3 mb-4 mt-4 bg-white">
            <div class="card-body">
              <ngx-picture src="../../assets/Placement.svg" alt="" class="w-100 h-20" width="100%" height="20%" [lazyLoad]="true" media="(max-width: 599.99px)" type="image/webp">
              </ngx-picture>
              <h1 class="card-title alert alert-primary shadow-lg">Training & Placement</h1>
              <div class="card-text">
                <ul>
                  <li>
                    <h3>Business Communications</h3>
                  </li>
                  <li>
                    <h3>Basic / Advanced Excel</h3>
                  </li>
                  <li>
                    <h3>VBA / Micro</h3>
                  </li>
                  <li>
                    <h3>Interview Skills</h3>
                  </li>
                  <li>
                    <h3>Soft Skills Development</h3>
                  </li>
                </ul>
              </div>
              <a routerLink="./placement" mat-button
                class="btn button_color shadow-lg text-uppercase btn-lg  ml-4 mt-3">Register</a>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-sm-6">
          <div class="card card1 shadow-lg p-3 mb-3 mt-4 bg-white">
            <div class="card-body">
              <ngx-picture src="../../assets/graphicsdesigner.svg" alt="" class="w-100 h-20" width="100%" height="20%" [lazyLoad]="true"
                media="(max-width: 599.99px)" type="image/webp">
              </ngx-picture>
              <h1 class="card-title alert alert-primary shadow-lg">Graphics Designing</h1>
              <div class="card-text">
                <ul>
                  <li>
                    <h3>Social Media Post</h3>
                  </li>
                  <li>
                    <h3>Visting and Wedding Card</h3>
                  </li>
                  <li>
                    <h3>Logo Making</h3>
                  </li>
                  <li>
                    <h3>Business Post</h3>
                  </li>
                  <li>
                    <h3>Soft Skills Development</h3>
                  </li>
                </ul>
              </div>
              <a routerLink="#" mat-button class="btn button_color shadow-lg text-uppercase btn-lg  ml-4 mt-2">Read
                More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid section pt-6 rounded bg_pattern">
    <div class="row justify-content-center align-items-center section">
      <p class="text-center mb-5 mt-5 " style="font-size: 4em;">Our Values</p>
    </div>
    <div class="row justify-content-center align-items-center">
      <div class="col-md-6 col-sm-1">
        <ngx-picture src="../../assets/example-8.svg" alt="" class="w-75 h-30" width="75%" height="30%"
          [lazyLoad]="true" media="(max-width: 599.99px)" type="image/webp">
        </ngx-picture>
      </div>
      <div class="col-md-6 border-left">
        <h2 class="mt-4 text_color"><i class="fas fa-hand-point-right"></i> We believe in transparency1 at work & our
          clients.
        </h2>
        <h2 class="mt-5 text_color"><i class="fas fa-hand-point-right"></i> We ensure hassle free delivery within the
          stipulated time frame.</h2>
        <h2 class="mt-5 text_color"><i class="fas fa-hand-point-right"></i> We are committed to delivering high-quality
          services.</h2>
        <h2 class="mt-5 text_color"><i class="fas fa-hand-point-right"></i> We work tirelessly for our clients to ensure
          they
          earn abounding profits.</h2>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid section1 rounded bgcard">
  <div class="row justify-content-center align-items-center">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path fill="#FFFF" fill-opacity="1"
        d="M0,32L48,64C96,96,192,160,288,170.7C384,181,480,139,576,138.7C672,139,768,181,864,192C960,203,1056,181,1152,197.3C1248,213,1344,267,1392,293.3L1440,320L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z">
      </path>
    </svg>
    <p class="text-white p-4" style="font-size: 4em;">Contact Us</p>
  </div>
  <div class="row p-5 pb-5 justify-content-center align-items-center">
    <app-callcenter></app-callcenter>
  </div>
</div>
<app-footer></app-footer>
