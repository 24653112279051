<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"><header>
  <div class="container-fluid mx-1">
    <nav class="navbar navbar-toggleable-md navbar-expand-lg">
      <a class="navbar-brand" href="#">
        <img src="../../assets/logo 1.jpg" class="w-20 h-10" width="180" height="75">
        <h2 class="text_color">AB Infotech Solutions</h2>
      </a>
      <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fas fa-align-right text-primary"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <div class="mr-auto"></div>
        <ul class="navbar-nav">
          <li class="nav-item active">
            <a class="nav-link" href="#">HOME
              <span class="sr-only">(current)</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="../services">SERVICES</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="../graphics">CONTACT US</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>
